import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarProvider,
  SidebarRail,
} from "@/components/ui/sidebar";
import { getUserWithSession } from "@/lib/auth/isAuth.server";
import { getMultipleConfig } from "@/lib/db/config.server";
import { Link, Outlet, useRouteLoaderData } from "react-router";
import { Theme, useTheme } from "remix-themes";
import type { Route } from "./+types/Layout";

export const links: Route.LinksFunction = () => [
  {
    rel: "manifest",
    href: "/dash.webmanifest",
  },
];

type MenuItem = {
  title: string;
  items: {
    title: string;
    href: string;
    label?: string;
    visible?: boolean;
  }[];
  icon: IconName;
  href: string;
};

export async function loader({ request }: Route.LoaderArgs) {
  const user = await getUserWithSession(request);

  const menu: MenuItem[] = [
    {
      title: "Productos",
      icon: "wallet" as const,
      visible: true,
      href: "/products",
      items: [
        {
          title: "Ver productos",
          href: "/products",
          visible: user.admin || user.design,
        },
        {
          title: "Ver colores",
          href: "/products/colors",
        },
      ],
    },
    {
      title: "Inventario",
      visible: user.admin || user.bodega,
      icon: "box" as const,
      href: "/inventory",
      items: [
        {
          title: "Ver inventario",
          href: "/inventory",
        },
        {
          title: "Bodega",
          href: "/inventory/bodega",
        },
        {
          title: "Imprimir etiquetas",
          href: "/inventory/tags",
        },
        {
          title: "Entradas y salidas",
          href: "/inventory/entry",
        },
        {
          title: "Historial de cambios",
          href: "/inventory/changes",
        },
      ],
    },
    /*  {
      title: "Marketplaces",
      visible: user.admin,
      items: [
        {
          title: "Ordenes",
          href: "/orders",
        },
      ],
    }, */
    {
      title: "Al mayor",
      visible: user.admin || user.vendedor,
      icon: "users" as const,
      href: "/mayor",
      items: [
        {
          title: "Catálogos",
          href: "/mayor/catalogs",
        },
        {
          title: "Clientes",
          href: "/mayor/clients",
          label: "Nuevo",
          visible: user.admin,
        },
        {
          title: "Ordenes",
          href: "/mayor/orders",
          label: "Nuevo",
          visible: user.admin,
        },
      ],
    },
    {
      title: "IA",
      visible: user.admin,
      icon: "bot" as const,
      href: "/chat",
      items: [
        {
          title: "Chat",
          href: "/chat",
          label: "Experimental",
        },
      ],
    },
  ]
    .map((item) => ({
      ...item,
      items: item.items.filter((i) => ("visible" in i ? i.visible : true)),
    }))
    .filter((i) => i.visible && i.items.length > 0);

  const envs = await getMultipleConfig([
    "Shopify/publicUrl",
    "Shopify/storeID",
    "Paris/seller",
  ]);

  return {
    menu,
    ENV: {
      shopifyURL: envs["Shopify/publicUrl"],
      shopifyStoreID: envs["Shopify/storeID"],
      parisSeller: user.admin ? envs["Paris/seller"] : undefined,
    },
    user: {
      name: user.name,
      email: user.email,
      image: user.image,
    },
  };
}

export function useDashboardData() {
  return useRouteLoaderData<typeof loader>("components/Dashboard/Layout")!;
}

function ChangeTheme() {
  const [, setTheme] = useTheme();
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger className="flex items-center gap-2">
        <IconLucideSun className="size-6 rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
        <IconLucideMoon className="absolute size-6 rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
        <span>Cambiar tema</span>
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent>
          <DropdownMenuItem onClick={() => setTheme(null)}>
            Sistema
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => setTheme(Theme.LIGHT)}>
            Claro
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setTheme(Theme.DARK)}>
            Oscuro
          </DropdownMenuItem>
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
}

export default function Dashboard({ loaderData }: Route.ComponentProps) {
  const { menu, user } = loaderData;
  return (
    <SidebarProvider style={{ viewTransitionName: "sidebar" }}>
      <Sidebar collapsible="icon" className="print:hidden">
        <SidebarContent>
          <div
            style={{ height: "env(titlebar-area-height, 0)" }}
            className="drawable-area-app"
          ></div>
          <SidebarGroup>
            <SidebarGroupLabel>Secciones</SidebarGroupLabel>
            <SidebarMenu>
              {menu.map((item) => (
                <Collapsible
                  key={item.title}
                  asChild
                  defaultOpen={true}
                  className="group/collapsible"
                >
                  <SidebarMenuItem>
                    <CollapsibleTrigger asChild>
                      <SidebarMenuButton
                        tooltip={item.title}
                        className="[&>i]:size-4 [&>i]:shrink-0"
                      >
                        {icons[item.icon]}
                        <span>{item.title}</span>
                        <IconLucideChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                      </SidebarMenuButton>
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {item.items?.map((subItem) => (
                          <SidebarMenuSubItem key={subItem.title}>
                            <SidebarMenuSubButton to={subItem.href} end={true}>
                              <span>{subItem.title}</span>
                            </SidebarMenuSubButton>
                          </SidebarMenuSubItem>
                        ))}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </SidebarMenuItem>
                </Collapsible>
              ))}
            </SidebarMenu>
          </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuItem>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton
                    size="lg"
                    className="data-[state=open]:text-sidebar-accent-foreground"
                  >
                    <Avatar className="h-8 w-8 rounded-lg">
                      <AvatarImage
                        src={user.image ?? undefined}
                        alt={"Avatar de " + user.name}
                      />
                      <AvatarFallback className="rounded-lg">
                        {user.name?.[0] ?? "E"}
                      </AvatarFallback>
                    </Avatar>
                    <div className="grid flex-1 text-left text-sm leading-tight">
                      <span className="truncate font-semibold">
                        {user.name ?? "Usuario no encontrado"}
                      </span>
                      <span className="truncate text-xs">
                        {user.email ?? "Correo no encontrado"}
                      </span>
                    </div>
                    <IconLucideChevronDown className="ml-auto size-4" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="w-(--radix-dropdown-menu-trigger-width) min-w-56 rounded-lg"
                  side="bottom"
                  align="end"
                  sideOffset={4}
                >
                  <DropdownMenuLabel className="p-0 font-normal">
                    <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                      <Avatar className="h-8 w-8 rounded-lg">
                        <AvatarImage
                          src={user.image ?? undefined}
                          alt={"Avatar de " + user.name}
                        />
                        <AvatarFallback className="rounded-lg">
                          {user.name?.[0] ?? "E"}
                        </AvatarFallback>
                      </Avatar>
                      <div className="grid flex-1 text-left text-sm leading-tight">
                        <span className="truncate font-semibold">
                          {user.name ?? "Usuario no encontrado"}
                        </span>
                        <span className="truncate text-xs">
                          {user.email ?? "Correo no encontrado"}
                        </span>
                      </div>
                    </div>
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <ChangeTheme />
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem asChild>
                      <Link
                        to="/credentials/excel"
                        className="flex items-center gap-2"
                      >
                        <IconLucideFileSpreadsheet className="size-6" />
                        <span>Credenciales Excel</span>
                      </Link>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem asChild>
                    <form method="post" action="/api/logout">
                      <button
                        type="submit"
                        className="flex w-full items-center gap-2"
                      >
                        <IconLucideLogOut className="size-6" />
                        Cerrar sesión
                      </button>
                    </form>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
        <SidebarRail />
      </Sidebar>
      <SidebarInset className="grid h-dvh grid-rows-[auto_1fr] overflow-hidden bg-muted/40 scrollbar-track-transparent scrollbar-thumb-primary print:overflow-visible print:bg-transparent">
        <Outlet />
      </SidebarInset>
    </SidebarProvider>
  );
}

export const shouldRevalidate = () => false;

const icons = {
  wallet: <IconLucideWallet />,
  box: <IconLucideBox />,
  users: <IconLucideUsers />,
  bot: <IconLucideBot />,
};

type IconName = keyof typeof icons;
